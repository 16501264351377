import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const Works = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;
	// const category = location.state?.category;
	// const posts = data.allMarkdownRemark.nodes;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="Works" />
			<hr />
			<h2>W o r k s</h2>
			<p className="works-discription">
				当事務所はパンフレット、チラシ・フライヤー、ポスターなどの広報・宣伝に関わるさまざまな印刷物・販促物デザイン、WEBサイトなどの企画・制作を行うデザイン制作会社です。ご相談から製品のお届けまでを１パッケージでご提供しています。業務には多種多様な姿があり、私たちの向き合い方や取組み方にも多くのバリエーションがあります。
			</p>
			<div className="works-contents-block-wrapper">
				<div className="works-contents-block">
					<ol style={{ listStyle: `none` }}>
						{/* {posts.map((post) => {
							const category = post.frontmatter.category;
							const image = getImage("../images/" + post.frontmatter.image);

							return (
								<li key={post.fields.slug}>
									<article className="post-list-item" itemScope itemType="http://schema.org/Article">
										<GatsbyImage
											className="works-image"
											src={image}
											quality={80}
											alt={post.frontmatter.imageAlt}
										/>
										<h2>{post.frontmatter.title}</h2>
										<small>{post.frontmatter.date}</small>
										<p>{post.frontmatter.description}</p>
										<p>{image}</p>
									</article>
								</li>
							);
						})} */}
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category1">
									地域
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0001.jpg"
									quality={80}
									alt="post0001.jpg"
								/>
								<h2>人がささえあって生きるまちづくりへ、一歩ずつ。</h2>
								<p>
									◉まちに地域ケアシステムが根づくことを目指す多様な取組とそれに関わるみなさんをご紹介するパンフレット制作をお手伝いしました。◉支え合いが簡単にはいかない現代社会を知恵を出し合って少しずつ変えていこう、そう考える人は企業や自治会などいろいろ。今は「もっと根っこを広げたい」という段階です。まだ馴染みにくい呼び名ですが、それぞれに地域性を反映させたものとして育っていくと面白いですね。
								</p>
							</article>
						</li>
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category2">
									福祉
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0002.jpg"
									quality={80}
									alt="post0002.jpg"
								/>
								<h2>原稿を読ませていただき、確かに自分は理解していないと思いました。</h2>
								<p>
									◉この業務はいただいた文章に挿絵を添えて読みやすくまとめるというものでした。内容はまさに「理解するため」のアドバイス。取りまく環境がわずかに変わるだけでも、多くの人が自由に働き、自立した人生を目指すことができるようになるでしょう。◉文章をよく読み、事実が正しく伝えられるように心がけた仕事ですが、皆さんが望むリアルが表わせるように。もっと理解しなくては。
								</p>
							</article>
						</li>
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category3">
									子育て
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0003.jpg"
									quality={80}
									alt="post0003.jpg"
								/>
								<h2>世の中はうんと面白いよ！…子どもたちに届けるメッセージです。</h2>
								<p>
									◉首都圏の幼稚園児がいるご家庭で読んでいただけるように、ここまで70回ほど発行されてきた季刊広報誌です。親御様と子どもたちが紙面を見ながら楽しく会話をしてもらえたら、そんな趣旨で発行されています。◉文字と親しむ前の子どもたちに、世の中のあれこれを絵や短い言葉で紹介しています。純粋に子どもたちが「かわいいなぁ」と感じられるように、一つ一つの挿絵を丁寧に描いています。
								</p>
							</article>
						</li>
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category4">
									行政
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0004.jpg"
									quality={80}
									alt="post0004.jpg"
								/>
								<h2>元気なまちを象徴する見所が表紙をにぎわす冊子づくり。</h2>
								<p>
									◉このまちはとてもアクティブで、人や団体が一生懸命に過ごしている印象です。それらがうまく結びついたところに何かが生まれていて、活力ってこういうことなのだなと感じます。◉こういった冊子は多くの市町村で見られますが、ここではどんどん元気を生み出すこのまちにふさわしい「見どころ探訪」型の表紙を担当職員の方と相談しながら形にしたものです。
								</p>
							</article>
						</li>
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category5">
									ものづくり
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0005.jpg"
									quality={80}
									alt="post0005.jpg"
								/>
								<section>
									<h2>地域の名産品をみんなで応援して育てていくためには…。</h2>
									<p>
										◉まちの名産品・特産品をご紹介することは大切です。良いものづくりを育て、担い手の発掘や育成にも繋がることでしょう。◉この制作はカメラマンとともに広い市内を回り、製品の情報集めからやらせていただいたのが印象深い作業でした。◉見出しの中には「私たちが誇る」「地域が認めた逸品」など地域を盛り上げるための意気込みあふれる言葉が踊っています。◉こういった名産品・特産品を育てる過程の第一段階「みんなでそれを知る」という役目を担ったパンフレット制作だったと思います。
									</p>
								</section>
							</article>
						</li>
						<li>
							<article className="post-list-item" itemScope itemType="http://schema.org/Article">
								<span className="works-category" id="category6">
									other
								</span>
								<StaticImage
									className="works-image"
									src="../images/post0006.jpg"
									quality={80}
									alt="post0006.jpg"
								/>
								<section>
									<h2>お手紙の束をお預かりして文集としてまとめさせていただきました。</h2>
									<p>
										◉戦前〜戦後を通じて共に学んだ同窓生約70名が、大学同窓会に寄せたお手紙をまとめた文集です。戦争の影に覆われた暗い時代だったはずですが、みなさんが描いているのは、優しい友情にあふれた青春時代の思い出でした。◉表紙の桜は大学構内に今も残る樹齢80年ほどの大樹を撮影したもの。ちょうど筆者の方々と同じ世代です。敗戦したとはいえ、この人達が支えてきた日本が立派な復興を遂げたのは当然だったのかもしれないと感じる業務でした。
									</p>
								</section>
							</article>
						</li>
					</ol>
				</div>
			</div>
		</Layout>
	);
};

export default Works;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					category
					title
					description
					image
					imageAlt
				}
			}
		}
	}
`;
